export const Organization = [
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАЕВАЯ КЛИНИЧЕСКАЯ БОЛЬНИЦА"',
    label: 'Краевая клиническая больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАЕВАЯ ДЕТСКАЯ КЛИНИЧЕСКАЯ БОЛЬНИЦА"',
    label: 'Краевая детская клиническая больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАЕВАЯ БОЛЬНИЦА № 3"',
    label: 'Краевая больница № 3',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ АВТОНОМНОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАЕВАЯ БОЛЬНИЦА № 4"',
    label: 'Краевая больница № 4',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАЕВОЙ ЦЕНТР МЕДИЦИНСКОЙ РЕАБИЛИТАЦИИ ЯМКУН"',
    label: 'Краевой центр медицинской реабилитации Ямкун',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ЗАБАЙКАЛЬСКИЙ КРАЕВОЙ ОНКОЛОГИЧЕСКИЙ ДИСПАНСЕР"',
    label: 'Забайкальский краевой онкологический диспансер',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАЕВОЙ КОЖНО-ВЕНЕРОЛОГИЧЕСКИЙ ДИСПАНСЕР"',
    label: 'Краевой кожно-венерологический диспансер',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ЗАБАЙКАЛЬСКИЙ КРАЕВОЙ КЛИНИЧЕСКИЙ ГОСПИТАЛЬ ДЛЯ ВЕТЕРАНОВ ВОЙН"',
    label: 'Забайкальский краевой клинический госпиталь для ветеранов войн',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ АВТОНОМНОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАЕВАЯ СТОМАТОЛОГИЧЕСКАЯ ПОЛИКЛИНИКА"',
    label: 'Краевая стоматологическая поликлиника',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАЕВОЙ ВРАЧЕБНО-ФИЗКУЛЬТУРНЫЙ ДИСПАНСЕР"',
    label: 'Краевой врачебно-физкультурный диспансер',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАЕВАЯ КЛИНИЧЕСКАЯ ИНФЕКЦИОННАЯ БОЛЬНИЦА"',
    label: 'Краевая клиническая инфекционная больница',
  },
  {
    value:
      'КРАЕВОЕ ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ДЕТСКАЯ КРАЕВАЯ КЛИНИЧЕСКАЯ БОЛЬНИЦА" ИМЕНИ А. К. ПИОТРОВИЧА МИНИСТЕРСТВА ЗДРАВООХРАНЕНИЯ ХАБАРОВСКОГО КРАЯ',
    label: 'Детская краевая клиническая больница имени А. К. Пиотровича',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ГОРОДСКАЯ КЛИНИЧЕСКАЯ БОЛЬНИЦА № 1"',
    label: 'Городская клиническая больница № 1',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ГОРОДСКАЯ КЛИНИЧЕСКАЯ БОЛЬНИЦА № 2"',
    label: 'Городская клиническая больница № 2',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "АКШИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Акшинская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "АЛЕКСАНДРОВО-ЗАВОДСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Александрово-Заводская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "БАЛЕЙСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Балейская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "БОРЗИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Борзинская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ГАЗИМУРО-ЗАВОДСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Газимуро-Заводская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ЗАБАЙКАЛЬСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Забайкальская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КАЛАРСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Каларская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КАЛГАНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Калганская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КАРЫМСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Карымская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КРАСНОЧИКОЙСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Красночикойская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КЫРИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Кыринская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "МОГОЧИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Могочинская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "НЕРЧИНСКО-ЗАВОДСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Нерчинско-Заводская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "НЕРЧИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Нерчинская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ОЛОВЯННИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Оловяннинская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ОНОНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Ононская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ПЕТРОВСК-ЗАБАЙКАЛЬСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Петровск-Забайкальская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ПРИАРГУНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Приаргунская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "СРЕТЕНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Сретенская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ТУНГОКОЧЕНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Тунгокоченская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "УЛЁТОВСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Улётовская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ХИЛОКСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Хилокская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ЧИТИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Читинская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ЧЕРНЫШЕВСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Чернышевская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ШЕЛОПУГИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА "',
    label: 'Шелопугинская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ АВТОНОМНОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ШИЛКИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Шилкинская центральная районная больница',
  },
  {
    value: 'ГОСУДАРСТВЕННОЕ АВТОНОМНОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "АГИНСКАЯ ОКРУЖНАЯ БОЛЬНИЦА"',
    label: 'Агинская окружная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "ДУЛЬДУРГИНСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Дульдургинская центральная районная больница',
  },
  {
    value:
      'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "МОГОЙТУЙСКАЯ ЦЕНТРАЛЬНАЯ РАЙОННАЯ БОЛЬНИЦА"',
    label: 'Могойтуйская центральная районная больница',
  },
  {
    value: 'ЧАСТНОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ "КЛИНИЧЕСКАЯ БОЛЬНИЦА "РЖД-МЕДИЦИНА" ГОРОДА ЧИТА"',
    label: 'Клиническая больница "РЖД-Медицина" города Чита',
  },
  {
    value: 'ФЕДЕРАЛЬНОЕ ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ',
    label: 'Федеральное государственное учреждение здравоохранения',
  },
];
