export const Department = [
  {
    value: 'Административно-хозяйственная часть',
    label: 'Административно-хозяйственная часть',
  },
  {
    value: ' Хирургическое отделение опухолей молочной железы',
    label: ' Хирургическое отделение опухолей молочной железы',
  },
  {
    value: 'Отделение радиотерапии',
    label: 'Отделение радиотерапии',
  },
  {
    value: 'Отделение анестезиологии-реанимации',
    label: 'Отделение анестезиологии-реанимации',
  },
  {
    value: 'Отделение противоопухолевой лекарственной терапии',
    label: 'Отделение противоопухолевой лекарственной терапии',
  },
  {
    value: 'Отделение противоопухолевой лекарственной терапии',
    label: 'Отделение противоопухолевой лекарственной терапии',
  },
  {
    value: 'Клинико-диагностическая лаборатория',
    label: 'Клинико-диагностическая лаборатория',
  },
  {
    value: 'Поликлиническое отделение',
    label: 'Поликлиническое отделение',
  },

  {
    value: 'Отделение лучевой диагностики',
    label: 'Отделение лучевой диагностики',
  },
  {
    value: 'Торакальное хирургическое отделение',
    label: 'Торакальное хирургическое отделение',
  },
  {
    value: 'Отделение противоопухолевой лекарственной терапии солидных опухолей',
    label: 'Отделение противоопухолевой лекарственной терапии солидных опухолей',
  },
  {
    value: 'Эндоскопическое отделение',
    label: 'Эндоскопическое отделение',
  },
  {
    value: 'Цитологическая лаборатория',
    label: 'Цитологическая лаборатория',
  },
  {
    value: 'Хирургическое отделение опухолей женской репродуктивной',
    label: 'Хирургическое отделение опухолей женской репродуктивной',
  },
  {
    value: 'Отделение радиологии ',
    label: 'Отделение радиологии ',
  },
  {
    value: 'Эпидемиологический отдел',
    label: 'Эпидемиологический отдел',
  },
  {
    value: 'Планово-экономический отдел',
    label: 'Планово-экономический отдел',
  },
  {
    value: 'Приемное отделение',
    label: 'Приемное отделение',
  },
  {
    value: 'Отдел контроля качества оказания медицинской помощи',
    label: 'Отдел контроля качества оказания медицинской помощи',
  },
  {
    value: 'Отделение рентгенохирургических методов диагностики и лечения',
    label: 'Отделение рентгенохирургических методов диагностики и лечения',
  },
  {
    value: 'Операционный блок',
    label: 'Операционный блок',
  },
  {
    value: 'Общебольничный медицинский персонал',
    label: 'Общебольничный медицинский персонал',
  },
  {
    value: 'Хирургическое отделение опухолей женской репродуктивной системы',
    label: 'Хирургическое отделение опухолей женской репродуктивной системы',
  },
  {
    value: 'Отделение радиологии',
    label: 'Отделение радиологии',
  },
  {
    value: 'Отделение гематологии и химиотерапии',
    label: 'Отделение гематологии и химиотерапии',
  },
  {
    value: 'Хирургическое отделение опухолей мочеполовой системы',
    label: 'Хирургическое отделение опухолей мочеполовой системы',
  },
  {
    value: 'Хирургическое отделение опухолей головы и шеи',
    label: 'Хирургическое отделение опухолей головы и шеи',
  },
  {
    value: 'Организационно-методический отдел',
    label: 'Организационно-методический отдел',
  },

  {
    value: 'Абдоминальное хирургическое отделение',
    label: 'Абдоминальное хирургическое отделение',
  },
  {
    value: 'Детское онкологическое отделение',
    label: 'Детское онкологическое отделение',
  },
  {
    value: 'Патологоанатомическое отделение',
    label: 'Патологоанатомическое отделение',
  },
];
